import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import SelectFleet from "./pages/SelectFleet";
import IndividualStream from "./pages/IndividualStream";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={"/:encrypt"} element={<Login />} />
        <Route path={"dashboard/:id"} element={<Dashboard />} />
        <Route path={"selectfleet/:email"} element={<SelectFleet />} />
        <Route path={"stream/:id"} element={<IndividualStream />} />
      </Routes>
    </div>
  );
}

export default App;
