/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRobotDirectionbyMapbox,
  robotmsgAction,
} from "../../../redux/actions";
import { getFleetListUser } from "../../../apis";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

let origin;
let destination;

mapboxgl.accessToken =
  "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

const checkActionId = (commandListData, props) => {
  if (
    !localStorage.getItem("actionId") ||
    localStorage.getItem("actionId") === "null"
  ) {
    localStorage.setItem(
      "actionId",
      JSON.stringify({
        actionType: commandListData.actionType,
        actionId: commandListData.actionId,
      })
    );
    return true;
  } else {
    if (
      commandListData.actionId !==
      JSON.parse(localStorage.getItem("actionId")).actionId
    ) {
      localStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      return true;
    } else {
      return false;
    }
  }
};

const MapboxMain = (props) => {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [, setPolygonarray] = useState([]);
  const [robotMarker, setRobotMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [nogozonescoordinates, setnogozonescoordinates] = useState(
    props.nogozones.map((data) => [data.lng, data.lat])
  );
  const [geofencecoordinates, setgeofencecoordinates] = useState(
    props.geofencezone.map((data) => [data.lng, data.lat])
  );

  const mapRef = useRef(null);

  const dispatch = useDispatch();

  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const { robotmsgData } = useSelector((state) => state.robotMessageReducer);
  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );

  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  robotMarkerDiv.style.backgroundImage =
    "url(/assets/images/robot-icon-pickup_1.svg)";
  robotMarkerDiv.style.borderRadius = "100%";
  robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const destinationMarkerDiv = document.createElement("div");
  destinationMarkerDiv.style.width = "35px";
  destinationMarkerDiv.style.height = "35px";
  destinationMarkerDiv.style.backgroundImage =
    "url(/assets/images/robot-icon-dropoff_1.svg)";
  destinationMarkerDiv.style.borderRadius = "100%";
  destinationMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const updateRobotpath = () => {
    const map = mapRef.current;
    if (!props.robotpathcheck) {
      if (
        robotDetails[props.robotId].hasOwnProperty("path") &&
        robotDetails[props.robotId].path.length > 0 &&
        map.isStyleLoaded()
      ) {
        const newrobotPath = robotDetails[props.robotId].path.map(
          (pathDirection) => [pathDirection.lng, pathDirection.lat]
        );
        const mySource = map.getSource("robotPath");
        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          });
        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#0f0");
      }
    } else {
      if (
        robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).actionType === "MOVELOCATION" &&
        map.isStyleLoaded() &&
        checkActionId(
          robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ),
          props
        )
      ) {
        origin = {
          lat: robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).latitude,
          lng: robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).longitude,
        };
        console.log(origin);
        destination = {
          lat: props.robotlatitude,
          lng: props.robotlongitude,
        };

        dispatch(getRobotDirectionbyMapbox(origin, destination));
      }

      if (robotDirections.hasOwnProperty("routes")) {
        const mapboxPath = robotDirections.routes[0].geometry.coordinates;
        const mySource = map.getSource("robotPath");
        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: mapboxPath,
            },
          });
        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#A020F0");
      }
    }
  };

  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          robotDetails[props.robotId].longitude,
          robotDetails[props.robotId].latitude,
        ],
        zoom: zoom,
      });

      const newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
        .setLngLat([
          robotDetails[props.robotId].longitude,
          robotDetails[props.robotId].latitude,
        ])
        .addTo(newMap);

      const newDestinationMarker = new mapboxgl.Marker(destinationMarkerDiv)
        .setLngLat([0, 0])
        .addTo(newMap);
      setDestinationMarker(newDestinationMarker);

      newMap.on("load", () => {
        newMap.addSource("nogozone", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [nogozonescoordinates],
            },
          },
        });
        newMap.addLayer({
          id: "nogozoneoutline",
          type: "line",
          source: "nogozone",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      newMap.on("load", () => {
        newMap.addSource("geofence", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",

              coordinates: [geofencecoordinates],
            },
          },
        });
        newMap.addLayer({
          id: "geofenceoutline",
          type: "line",
          source: "geofence",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      let newpolygonarray = [];
      let offset = 0.00003;
      props.fleetzones.map((polygon, index) => {
        newpolygonarray.push(
          polygon.map((data) => [data.lng, data.lat + offset])
        );
        newMap.on("load", () => {
          newMap.addSource("fleetzone-" + index, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",

                coordinates: [newpolygonarray[index]],
              },
            },
          });
          newMap.addLayer({
            id: "fleetzoneoutline-" + index,
            type: "fill",
            source: "fleetzone-" + index,
            layout: {},
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 1,
            },
          });
        });

        return newpolygonarray;
      });

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      newMap.on("idle", function () {
        newMap.resize();
      });
      setPolygonarray(newpolygonarray);
      setRobotMarker(newRobotMarker);
      setMap(newMap);
      mapRef.current = newMap;
    };
    if (!map) {
      initializeMap();
    }
    return () => {};
  }, [map, props.nogozones, props.fleetzones, props.geofencezone, setMap]);

  useEffect(() => {
    if (
      (robotmsgData && robotmsgData.length > 0 && !robotmsgData[0].location) ||
      !robotmsgData
    ) {
      getFleetListUser(
        localStorage.getItem("useremail"),
        localStorage.getItem("token")
      ).then((res) => {
        dispatch(robotmsgAction(res.data.fleet));
      });
    }

    if (map && map.isStyleLoaded()) {
      if (robotmsgData && robotmsgData[0].location === "zaragoza") {
        !map.getLayer("zaragoza-okoutline") &&
          map.addLayer({
            id: "zaragoza-okoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockddp17ir2onppxm67ul4-2322c",
            },
            "source-layer": "Zaragoza-OK",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#0000ff",
              "line-width": 2,
            },
          });

        !map.getLayer("zaragoza-goodoutline") &&
          map.addLayer({
            id: "zaragoza-goodoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockw3y0x522ophzjv42pw5-05s1c",
            },
            "source-layer": "Zaragoza-GOOD",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#027148",
              "line-width": 2,
            },
          });

        !map.getLayer("zaragoza-difficultoutline") &&
          map.addLayer({
            id: "zaragoza-difficultoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocljvn0yvs2cphplde8mwp-65qz7",
            },
            "source-layer": "Zaragoza-DIFFICULT",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#8B0000",
              "line-width": 2,
            },
          });

        !map.getLayer("zaragoza-nooutline") &&
          map.addLayer({
            id: "zaragoza-nooutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocqzf906lb2bo9xa6o69r5-5ubuf",
            },
            "source-layer": "Zaragoza-NO",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#FF0000",
              "line-width": 2,
            },
          });
      }

      if (
        !map.getSource("robotPath") &&
        ((robotDetails[props.robotId].hasOwnProperty("path") &&
          robotDetails[props.robotId].path.length > 0) ||
          (robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
            robotDetails[props.robotId].robotCommandList.length > 0 &&
            robotDetails[props.robotId].robotCommandList.find(
              (x) => x.actionPriority === 1
            ).actionType === "MOVELOCATION"))
      ) {
        var newrobotPath = [];
        newrobotPath =
          robotDetails[props.robotId].path &&
          robotDetails[props.robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);

        map.addSource("robotPath", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          },
        });

        map.addLayer({
          id: "path",
          type: "line",
          source: "robotPath",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0f0",
            "line-width": 5,
          },
        });
      }
    }

    if (robotMarker) {
      robotMarker.setLngLat([
        robotDetails[props.robotId].longitude,
        robotDetails[props.robotId].latitude,
      ]);
    }

    if (
      destinationMarker &&
      robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
      robotDetails[props.robotId].robotCommandList.length > 0 &&
      robotDetails[props.robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      )
    ) {
      destinationMarker.setLngLat([
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).longitude,
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).latitude,
      ]);
    }

    let offset = 0.00003;
    setnogozonescoordinates(
      props.nogozones.map((data) => [data.lng, data.lat + offset])
    );
    setgeofencecoordinates(
      props.geofencezone.map((data) => [data.lng, data.lat + offset])
    );
    let newpolygonarray = [];
    props.fleetzones.map((polygon, index) => {
      newpolygonarray.push(polygon.map((data) => [data.lng, data.lat]));
      return newpolygonarray;
    });
    setPolygonarray(newpolygonarray);
    if (
      robotDetails[props.robotId] &&
      robotDetails[props.robotId].hasOwnProperty("path")
    ) {
      updateRobotpath();
    }
  }, [
    robotDetails[props.robotId].longitude,
    robotDetails[props.robotId].latitude,
    robotMarker,
    destinationMarker,
    props.robotId,
    props.fleetzones,
    props.geofencezone,
    props.nogozones,
    props.robotpathcheck,
    dispatch,
    robotDirections,
    robotDetails,
    robotDetails[props.robotId],
    robotDetails[props.robotId].path,
  ]);

  return (
    <>
      <div style={{ height: "100%", width: props.width }}>
        <div
          id="map-container-id"
          // ref={mapContainer}
          className="map-container"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      {props.robotpathcheck &&
        robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).actionType === "MOVELOCATION" && <></>}
    </>
  );
};
export default memo(MapboxMain);
