import { useState } from "react";
import { useParams } from "react-router-dom";

export const useController = () => {
  const [sidebar, setSideBar] = useState(true);
  const [showMinimizeBtn, setShowMinimizeButton] = useState(true);
  const [unlock, setUnlock] = useState(Boolean);

  //Unlocking Controller
  const unlockController = (val) => {
    setUnlock(val);
  };

  //fetching the Robot ID
  const params = useParams();
  const id = params.id;

  return {
    sidebar,
    setSideBar,
    showMinimizeBtn,
    setShowMinimizeButton,
    unlock,
    unlockController,
    id,
  };
};
