import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { robotmsgAction, storeRobotDetails } from "../../redux/actions";
import controller from "../../assets/svgs/controller.svg";
import Battery from "../layout/navbar/Battery";
import CustomCircle from "../joystick/CustomCircle";

function IndividualRobot(props) {
  const [isCheck, setisCheck] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let robotmsg = [];
  let fleedId = props.data.fleetId;

  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  const handlerobotdata = (robotdata) => {
    localStorage.setItem("fleetId", robotdata.fleetId);
    navigate(`/dashboard/${robotdata.robotId}`, {
      state: {
        name: robotdata,
        latitude: props.latitude,
        longitude: props.longitude,
      },
    });
  };

  // const operators = [
  //   {
  //     userName: "Hardik Sharma",
  //     shortName: "HS",
  //     user: true,
  //   },
  //   {
  //     userName: "John Doe",
  //     shortName: "JD",
  //     user: false,
  //   },
  // ];

  const updateState = useCallback(async () => {
    dispatch(storeRobotDetails(fleedId));
  }, [dispatch, fleedId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 2000);

  const getrobotStatus = (item, data) => {
    switch (item?.robotStatus) {
      case "AVAILABLE":
        return (
          <>
            <span className="text-left m-0 p-color-green">Available </span>{" "}
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              ({item.robotRunningState.split("_").join(" ")})
            </span>
          </>
        );
      case "UNAVAILABLE":
        return (
          <>
            <span className="text-left m-0 p-color-red"> Unavailable </span>{" "}
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              ({item.robotRunningState.split("_").join(" ")})
            </span>
          </>
        );
      case "CHARGING":
        return (
          <>
            <span className="text-left m-0 p-color-green">Charging </span>
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              ({item.robotRunningState.split("_").join(" ")})
            </span>
          </>
        );
      case "ERROR":
        robotmsg.push({
          fleetId: data?.fleetId,
          fleetName: data?.fleetName,
          robotStatus: item.robotStatus,
          robotId: item.robotId,
          robotBattery: item.robotBattery,
          latitude: data?.latitude,
          longitude: data?.longitude,
          teleop: item.teleop,
        });
        if (isCheck) {
          setTimeout(() => {
            dispatch(robotmsgAction(robotmsg));
            setisCheck(false);
          }, 4000);
        }
        return <p className="text-left m-0 p-color-red"> Error</p>;
      default:
        return (
          <p className="text-left m-0 p-color-red"> {item?.robotStatus}</p>
        );
    }
  };

  return (
    <>
      {Object.keys(robotDetails)?.length > 0 ? (
        <>
          {props.fleetLength === 0 && (
            <div className="card__item">No Robots available!</div>
          )}
          {props.fleetLength !== 0 &&
            Object.keys(robotDetails)?.map((data, index) => (
              <Fragment key={"card__item__" + index}>
                {robotDetails[data].fleetId === props.data.fleetId && (
                  <div
                    onClick={() => handlerobotdata(robotDetails[data])}
                    className="card__item"
                  >
                    <div className="inner-card-items">
                      <div className="card-item-left">
                        <div className="card-item-1">
                          <div
                            style={{
                              textDecoration: "none",
                              margin: "0px",
                            }}
                          >
                            {robotDetails[data]?.robotId}
                          </div>
                        </div>
                        <div
                          className="card-item-2"
                          style={{
                            color:
                              robotDetails[data]?.robotStatus === "AVAILABLE"
                                ? "#71cf62"
                                : "#EA6161",
                          }}
                        >
                          <div
                            style={{ margin: "0px", width: "max-content" }}
                            className="capitalize"
                          >
                            {getrobotStatus(robotDetails[data], props.data)}
                          </div>
                        </div>

                        {robotDetails[data].status && (
                          <div
                            className="card-item-3"
                            style={{
                              opacity: robotDetails[data]?.controllerStatus
                                ? "1"
                                : "0.2",
                            }}
                          >
                            <img
                              src={controller}
                              alt="controller"
                              className="controller"
                            />
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "10px",
                                fontWeight: "500",
                              }}
                            >
                              {robotDetails[data].controllerStatus
                                ? "ON"
                                : "OFF"}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="card-item-right">
                        <div className="card-item-right-1">
                          <div className="battery-icon">
                            <Battery perc={robotDetails[data].robotBattery} />

                            <p
                              style={{
                                margin: "0px",
                                fontSize: "8px",
                                fontWeight: "600",
                              }}
                            >
                              {Math.floor(
                                parseFloat(robotDetails[data].robotBattery, 10)
                              )}
                              %
                            </p>
                          </div>

                          <div className="signal-icon">
                            <svg
                              focusable="false"
                              dataprefix="fas"
                              dataicon="signal"
                              className="svg-inline--fa fa-signal "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                            >
                              <path
                                fill="currentColor"
                                d="M576 0c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V32c0-17.7 14.3-32 32-32zM448 96c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V128c0-17.7 14.3-32 32-32zM352 224V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32s32 14.3 32 32zM192 288c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32zM96 416v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V416c0-17.7 14.3-32 32-32s32 14.3 32 32z"
                              ></path>
                            </svg>
                          </div>
                          <div className="play-pause-icon">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              dataprefix="far"
                              dataicon="circle-pause"
                              className="svg-inline--fa fa-circle-pause "
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm224-72V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24zm112 0V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="card-item-right-2">
                          <div className="circle-icons">
                            {/* {operators.map((viewer, index) => {
                              return (
                                <div
                                  className="circle-icon"
                                  key={"circle__icon__" + index}
                                >
                                  <CustomCircle value={viewer} />
                                </div>
                              );
                            })} */}
                            {robotDetails[data].viewers &&
                              robotDetails[data].viewers.map(
                                (viewer, index) => {
                                  return (
                                    <div
                                      className="circle-icon"
                                      key={"circle__icon__" + index}
                                    >
                                      <CustomCircle value={viewer} />
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {index !== Object.keys(robotDetails).length - 1 ? (
                      <div className="card-white-line"></div>
                    ) : null}
                  </div>
                )}
              </Fragment>
            ))}
        </>
      ) : (
        <div className="SelectPage-robotList-wrapper">
          <h4 className="No-robots-available"> No Robots Available</h4>
        </div>
      )}
    </>
  );
}

export default memo(IndividualRobot);
