import { IoIosStats } from "react-icons/io";
import { RiPictureInPicture2Line } from "react-icons/ri";
import { TbLivePhoto, TbLivePhotoOff, TbScreenShare } from "react-icons/tb";
import { LuScreenShareOff } from "react-icons/lu";
import StreamStatsPopup from "./StreamStatsPopup";

const Stream = ({
  streamRef,
  streamLive,
  handleMetadataLoaded,
  streamStats,
  setStreamStats,
  showStreamStats,
  streamView,
  setShowStreamStats,
  isMetadataLoaded,
  togglePictureInPicture,
  handleExtendScreen,
  handleReduceScreen,
  streamRefLocal,
}) => {
  return (
    <>
      <div style={{ display: streamRefLocal }}>
        {streamLive ? (
          <div className="stream__status">
            <TbLivePhoto className="stream__status_live" />{" "}
            <span className="stream__status_text">Live</span>
          </div>
        ) : (
          <div className="stream__status">
            <TbLivePhotoOff className="stream__status_not_live" />{" "}
            <span className="stream__status_text">Not Live</span>
          </div>
        )}
      </div>
      <video
        style={{ filter: !streamLive && "blur(10px)", display: streamRefLocal }}
        className="cam__video"
        ref={streamRef}
        id="stream"
        autoPlay
        muted
        loop
        controls={false}
        onLoadedMetadata={handleMetadataLoaded}
      />

      <div className="custom__video_controls_container">
        {streamLive && (
          <button
            className="custom__video_controls custom__video_control_stream_stats"
            onClick={() => {
              if (!showStreamStats) {
                setShowStreamStats(true);
                streamView && streamView.webRTCPeer.initStats();
              } else {
                setShowStreamStats(false);
                streamView && streamView.webRTCPeer.stopStats();
              }
            }}
          >
            <IoIosStats className="custom__controls_icons" />
          </button>
        )}
        <button
          disabled={!isMetadataLoaded}
          className="custom__video_controls custom__video_control_picture_in_picture"
          onClick={togglePictureInPicture}
        >
          <RiPictureInPicture2Line className="custom__controls_icons" />
        </button>
        {streamRefLocal === "block" ? (
          <button
            className="custom__video_controls custom__video_control_screen_share"
            onClick={handleExtendScreen}
          >
            <TbScreenShare className="custom__controls_icons" />
          </button>
        ) : (
          <button
            className="custom__video_controls custom__video_control_screen_share"
            onClick={handleReduceScreen}
          >
            <LuScreenShareOff className="custom__controls_icons" />
          </button>
        )}
      </div>

      <StreamStatsPopup
        streamStats={streamStats}
        setStreamStats={setStreamStats}
        showStreamStats={showStreamStats}
      />
    </>
  );
};

export default Stream;
