import { Director, View } from "@millicast/sdk";
// import { WHEPClient } from "./whep.js";

// Create callback to generate a new token
const tokenGenerator = (robotId) =>
  Director.getSubscriber({
    streamName: robotId,
    // streamName: "testingstream",
    streamAccountId: process.env.REACT_APP_STREAM_ACCOUNT_ID,
    subscriberToken: process.env.REACT_APP_SUBSCRIBER_TOKEN,
  });

const viewer = async (
  // url,
  video,
  setStreamLive,
  setViewCount,
  setStreamStats,
  robotId,
  setStreamView
) => {
  const millicastView = new View(
    "testingstream",
    () => tokenGenerator(robotId),
    video
  );
  const activeSources = new Set();
  setStreamView(millicastView);

  millicastView.on("broadcastEvent", (event) => {
    const { name, data } = event;
    switch (name) {
      case "active":
        // activeSources.add(data.sourceId);
        setStreamLive(true);
        break;

      case "inactive":
        // activeSources.delete(data.sourceId);
        if (activeSources.size === 0) {
          setStreamLive(false);
        }
        break;
      case "viewercount":
        setViewCount(data.viewercount);
        break;
      default:
        break;
    }
  });

  try {
    await millicastView.connect({
      events: ["active", "inactive", "layers", "viewercount"],
    });
  } catch (e) {
    await millicastView.reconnect();
  }

  millicastView.webRTCPeer.on("stats", (stats) => {
    // console.log("Stats from event: ", stats);
    setStreamStats(stats);
  });
};

export default viewer;
