import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";
import MapboxMain from "./MapboxMain";

const SidebarMap = ({
  sidebar,
  setSideBar,
  showMinimizeBtn,
  setShowMinimizeButton,
  robotpathcheck,
  robotId,
  geofencezone,
  nogozones,
  fleetzones,
  robotlatitude,
  robotlongitude,
  robotDetails,
  streamRefLocal,
}) => {
  const showSidebar = () => {
    minimizeEffect();
    setSideBar(!sidebar);
  };

  const minimizeEffect = () => {
    setShowMinimizeButton(false);

    setTimeout(() => {
      setShowMinimizeButton(true);
    }, 2000);
  };

  return (
    <aside
      className="map"
      style={{
        overflow: sidebar ? "hidden" : "overflow",
      }}
    >
      {nogozones &&
        geofencezone &&
        fleetzones &&
        robotId &&
        robotDetails[robotId]?.latitude &&
        robotDetails[robotId]?.longitude && (
          <MapboxMain
            robotpathcheck={robotpathcheck}
            robotId={robotId}
            geofencezone={geofencezone}
            nogozones={nogozones}
            fleetzones={fleetzones}
            robotlatitude={robotlatitude}
            robotlongitude={robotlongitude}
            width={sidebar ? "100%" : "0%"}
          />
        )}
      {showMinimizeBtn && (
        <span
          id="minimize__ref"
          className={`sidebar__minimize__btn`}
          onClick={showSidebar}
          style={
            {
              right: sidebar && "-23px",
              left: !sidebar && "-20px",
              justifyContent: sidebar ? "start" : "end",
              visibility: sidebar ? "hidden" : "visible",
              zIndex: sidebar ? 2 : 0,
              background: !sidebar && "#735AB6",
              top: sidebar
                ? "calc((100vh - 25%) / 2)"
                : "calc((100vh - 25%) / 2)",
              display: streamRefLocal === "block" ? "flex" : "none",
            }
            // sidebar
            //   ? {
            //       right: "-23px",
            //       justifyContent: "start",
            //       visibility: "hidden",
            //       zIndex: 2,
            //     }
            //   : {
            //       left: "-20px",
            //       justifyContent: "end",
            //       visibility: "visible",
            //       background: "#735AB6",
            //       zIndex: 0,
            //       top: "calc((100vh - 50%) / 2)",
            //     }
          }
        >
          {sidebar ? (
            <VscChevronLeft className="sidebar__minimize__btn__icon" />
          ) : (
            <VscChevronRight className="sidebar__minimize__btn__icon" />
          )}
        </span>
      )}
    </aside>
  );
};

export default SidebarMap;
