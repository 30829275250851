/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { map } from "lodash/fp";
import { PadContainer, ReactInputs, inputs, Circle } from "./ControlBtnStyles";
import left_input from "../../assets/images/left_circle.png";
import right_input from "../../assets/images/right_circle.png";
import SignalingChannel from "../../lib/signaling-channel";

let decimalFixed = 2;
let intervalFreq = 50;
let PEER_ID;
let PEER_TYPE = "admin";
let Target;

const channel = new SignalingChannel(
  PEER_ID,
  PEER_TYPE,
  process.env.REACT_APP_SIGNALING_SERVER_URL,
  process.env.REACT_APP_TOKEN
);

channel.connect();

const controll_btn_images = [left_input, right_input];
const analogInputs = ["L", "R"];
const shoulderInputs = ["L2", "L1", "R2", "R1"];
const digitalInputs = [
  "dpadUp",
  "dpadDown",
  "dpadLeft",
  "dpadRight",
  "A",
  "B",
  "X",
  "Y",
  "start",
  "select",
  "home",
];

function Stick({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
  conlock,
}) {
  const [x, y] = module.getStick(inputName).value;
  const { pressed } = module.getButton(`${inputName}3`);
  const StickComponent = inputs[inputName];

  useEffect(() => {
    //Dead-Band Code
    let Dead_Band_X_Max = 0.0;
    let Dead_Band_X_Min = 0.0;
    let Max_V_X = 0.0;
    let Min_V_X = 0.0;
    let Dead_Band_Y_Max = 0.0;
    let Dead_Band_Y_Min = 0.0;
    let Max_V_Y = 0.0;
    let Min_V_Y = 0.0;
    let v_y = 0.0;
    let v_x = 0.0;

    if (inputName === "R") {
      Dead_Band_X_Max = 0.1;
      Dead_Band_X_Min = -0.1;
      Max_V_X = 1.0;
      // Min_V_X = 1.0
      Dead_Band_Y_Max = 0.1;
      Dead_Band_Y_Min = -0.1;
      Max_V_Y = 1.0;
      Min_V_Y = -1.0;
      v_y = 0.0;
      v_x = 0.0;

      if (x >= Dead_Band_X_Max) {
        v_x = Max_V_X * (1.0 / (1.0 - Dead_Band_X_Max)) * (x - Dead_Band_X_Max);
      } else if (x <= Dead_Band_X_Min) {
        v_x = Max_V_X * (1.0 / (1.0 + Dead_Band_X_Min)) * (x - Dead_Band_X_Min);
      } else {
        v_x = 0.0;
      }

      if (y >= Dead_Band_Y_Max) {
        v_y = Max_V_Y * (1.0 / (1.0 - Dead_Band_Y_Max)) * (y - Dead_Band_Y_Max);
      } else if (y <= Dead_Band_Y_Min) {
        v_y = Max_V_Y * (1.0 / (1.0 + Dead_Band_Y_Min)) * (y - Dead_Band_Y_Min);
      } else {
        v_y = 0.0;
      }
    } else if (inputName === "L") {
      Dead_Band_X_Max = 0.1;
      Dead_Band_X_Min = -0.1;
      Max_V_X = 1.0;
      Min_V_X = 1.0;
      Dead_Band_Y_Max = 0.1;
      Dead_Band_Y_Min = -0.1;
      Max_V_Y = 1.0;
      Min_V_Y = 1.0;
      v_y = 0.0;
      v_x = 0.0;

      //Added these conditions after 10/06/2023
      if (x >= Dead_Band_X_Max) {
        v_x = Max_V_X * (1.0 / (1.0 - Dead_Band_X_Max)) * (x - Dead_Band_X_Max);
      } else if (x <= Dead_Band_X_Min) {
        v_x = Max_V_X * (1.0 / (1.0 + Dead_Band_X_Min)) * (x - Dead_Band_X_Min);
      } else {
        v_x = 0.0;
      }

      if (y >= Dead_Band_Y_Max) {
        v_y = Max_V_Y * (1.0 / (1.0 - Dead_Band_Y_Max)) * (y - Dead_Band_Y_Max);
      } else if (y <= Dead_Band_Y_Min) {
        v_y = Max_V_Y * (1.0 / (1.0 + Dead_Band_Y_Min)) * (y - Dead_Band_Y_Min);
      } else {
        v_y = 0.0;
      }
    }

    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            axis: {
              ...allButtons.axis,
              [inputName]: {
                ...allButtons.axis[inputName],
                x: -v_x.toFixed(decimalFixed), //Reversed x and y values to negetive for both L and R after 10/06/2023
                y: -v_y.toFixed(decimalFixed),
              },
            },
          }
        : { ...allButtons }
    );
  }, [conlock, inputName, setAllButtons, x, y]);

  useEffect(() => {
    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            buttons: { ...allButtons.buttons, [`${inputName}3`]: +pressed },
          }
        : { ...allButtons }
    );
    channel.send(allButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conlock, inputName, pressed, setAllButtons]);

  return (
    <StickComponent
      onClick={() => {
        if (module.isConnected()) {
          module.stickBindOnPress(inputName, () => setWaitingFor(null));
          setWaitingFor(inputName);
        }
      }}
      style={{
        transform: `translate(${x * 65}px, ${y * 65}px)`,
        backgroundColor: pressed ? pressedColor : "",
      }}
    />
  );
}

function Digital({
  inputName,
  pressedColor,
  module,
  setWaitingFor,
  setAllButtons,
  allButtons,
  conlock,
}) {
  const { pressed } = module.getButton(inputName);
  const DigitalComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            buttons: { ...allButtons.buttons, [inputName]: +pressed },
          }
        : { ...allButtons }
    );
  }, [conlock, inputName, pressed, setAllButtons]);

  return (
    <DigitalComponent
      style={{ backgroundColor: pressed ? pressedColor : "" }}
      onClick={() => {
        if (module.isConnected()) {
          module.buttonBindOnPress(inputName, () => setWaitingFor(null));
          setWaitingFor(inputName);
        }
      }}
    />
  );
}

function Shoulder({ inputName, module, allButtons, setAllButtons, conlock }) {
  const { value } = module.getButton(inputName);
  const ShoulderComponent = inputs[inputName];

  useEffect(() => {
    setAllButtons((allButtons) =>
      conlock
        ? {
            ...allButtons,
            buttons: {
              ...allButtons.buttons,
              [inputName]: value === 1 ? Math.ceil(value) : 0,
            },
          }
        : { ...allButtons }
    );
  }, [conlock, inputName, setAllButtons, value]);

  return (
    <div
      style={{
        position: "absolute",
        transform: `translateY(${value * 10}px)`,
        width: "100%",
        height: "100%",
      }}
    >
      <ShoulderComponent />
    </div>
  );
}

export default function ControlBtn({
  backgroundColor,
  pressedColor,
  module,
  conlock,
  robotId,
}) {
  const [, setWaitingFor] = useState(null);
  const [allButtons, setAllButtons] = useState({
    buttons: {
      L1: 0,
      L2: 0,
      L3: 0,
      R1: 0,
      R2: 0,
      R3: 0,
      dpadUp: 0,
      dpadDown: 0,
      dpadLeft: 0,
      dpadRight: 0,
      A: 0,
      B: 0,
      X: 0,
      Y: 0,
      start: 0,
      select: 0,
      home: 0,
    },
    axis: {
      L: { x: 0, y: 0 },
      R: { x: 0, y: 0 },
    },
  });

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  PEER_ID = firstName + "_" + lastName;
  Target = robotId;

  useEffect(() => {
    if (conlock && Target) {
      const initialButtons = {
        buttons: { ...allButtons.buttons },
        axis: { ...allButtons.axis },
      };

      channel.sendTo(Target, initialButtons);

      const intervalId = setInterval(() => {
        channel.sendTo(Target, allButtons);
      }, intervalFreq);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      // console.log("Joystick is locked");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conlock, allButtons, channel, Target]);

  return (
    <>
      {map(
        (inputName) => (
          <div style={{ display: "none" }}>
            <Shoulder
              key={inputName}
              inputName={inputName}
              module={module}
              setAllButtons={setAllButtons}
              allButtons={allButtons}
              conlock={conlock}
            />
          </div>
        ),
        shoulderInputs
      )}
      {map(
        (inputName) => (
          <div style={{ display: "none" }}>
            <Digital
              key={inputName}
              inputName={inputName}
              pressedColor={pressedColor}
              module={module}
              setWaitingFor={setWaitingFor}
              setAllButtons={setAllButtons}
              allButtons={allButtons}
              conlock={conlock}
            />
          </div>
        ),
        digitalInputs
      )}
      <PadContainer disconnected={!module.isConnected()}>
        <ReactInputs
          style={{
            width: "410px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {analogInputs.map(
            (inputName, index) => (
              <Circle
                key={"controller__circle__" + index}
                style={{
                  backgroundImage: `url(${controll_btn_images[index]})`,
                }}
              >
                <Stick
                  key={"controller__stick__" + index}
                  inputName={inputName}
                  pressedColor={pressedColor}
                  module={module}
                  setWaitingFor={setWaitingFor}
                  setAllButtons={setAllButtons}
                  allButtons={allButtons}
                  conlock={conlock}
                />
              </Circle>
            ),
            analogInputs
          )}
        </ReactInputs>
      </PadContainer>
    </>
  );
}
