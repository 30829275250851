/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { getRobotDetail, storeRobotDetails } from "../redux/actions";
import { MainStream } from "../components/stream/main";
import Stream from "../components/stream/Stream";

const IndividualStream = () => {
  const [streamLive, setStreamLive] = useState(false);
  const [streamView, setStreamView] = useState();
  const [, setViewCount] = useState({});
  const [streamStats, setStreamStats] = useState();
  const [showStreamStats, setShowStreamStats] = useState(false);
  const [, setIsPictureInPicture] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);

  const streamRef = useRef(null);

  const dispatch = useDispatch();

  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  let fleetId = localStorage.getItem("fleetId");
  const isLogged = localStorage.getItem("isLoggedin");

  const params = useParams();
  let robotId = params.id;

  const openExtendedWindow = async () => {
    const windowDetails = await window.getScreenDetails();

    if (window.screen.isExtended) {
      windowDetails.screens.forEach((screen) => {
        if (!screen.isPrimary) {
          window.open(
            `/stream/${robotId}`,
            "",
            `width=${screen.availWidth},height=${screen.height},top=${screen.top},left=${screen.left},fullscreen=yes`
          );
        } else {
          localStorage.setItem("streamRef", "none");
        }
      });
    }
  };

  const handleExtendScreen = async () => {
    await openExtendedWindow();
  };

  const handleReduceScreen = () => {
    localStorage.setItem("streamRef", "block");
    window.close();
  };

  const togglePictureInPicture = () => {
    const videoElement = streamRef.current;

    if (!isMetadataLoaded) {
      console.log("Video metadata is not loaded yet.");
      return;
    }

    if (document.pictureInPictureElement === videoElement) {
      document
        .exitPictureInPicture()
        .then(() => {
          setIsPictureInPicture(false);
          streamRef.current.style.display = "block";
        })
        .catch((error) => {
          console.error("Failed to exit Picture-in-Picture mode:", error);
        });
    } else {
      videoElement
        .requestPictureInPicture()
        .then(() => {
          setIsPictureInPicture(true);
          // streamRef.current.style.display = "none";
        })
        .catch((error) => {
          console.error("Failed to enter Picture-in-Picture mode:", error);
        });
    }
  };

  const handleMetadataLoaded = () => {
    setIsMetadataLoaded(true);
  };

  const updateState = useCallback(async () => {
    let robotIdfromURL = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if (robotId === null || robotId === "" || robotId === undefined) {
      dispatch(storeRobotDetails(fleetId));
      let singlerobotdata = Object.keys(robotDetails).filter(
        (item) => item === robotIdfromURL
      );

      robotId = singlerobotdata.robotId;
    }

    if (isLogged === "LoginFalse") {
      window.location.replace("/");
    } else {
      dispatch(getRobotDetail(robotId));

      localStorage.setItem("kmlurl", robotDetails[robotId]?.kmlURL);
    }
  }, [dispatch, fleetId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();

      return () => {
        clearTimeout(timeout.current);
      };
    }, []);
  };

  useIntervalAsync(updateState, 2000);

  useEffect(() => {
    MainStream(
      // robotDetails[robotId] && robotDetails[robotId].whepurl,
      setStreamLive,
      setViewCount,
      setStreamStats,
      robotId,
      setStreamView
    );
  }, []);

  window.addEventListener("unload", (ev) => {
    // streamRef.current.display = "block";
    localStorage.setItem("streamRef", "block");
  });

  return (
    <section className="extended__screen_container">
      <Stream
        streamRef={streamRef}
        streamLive={streamLive}
        handleMetadataLoaded={handleMetadataLoaded}
        streamStats={streamStats}
        setStreamStats={setStreamStats}
        showStreamStats={showStreamStats}
        streamView={streamView}
        setShowStreamStats={setShowStreamStats}
        isMetadataLoaded={isMetadataLoaded}
        togglePictureInPicture={togglePictureInPicture}
        handleExtendScreen={handleExtendScreen}
        handleReduceScreen={handleReduceScreen}
      />
    </section>
  );
};

export default IndividualStream;
