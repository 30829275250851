/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { map } from "lodash/fp";
import { getfleetzones, getgeofenceZone, getnogozones } from "../apis";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRobotDetail, storeRobotDetails } from "../redux/actions";
import { useController } from "../custom-hooks/useController";
import { MainStream } from "../components/stream/main";
import { createJoymap, createQueryModule } from "joymap";
import ColorHash from "color-hash";
import RobotStatusBar from "../components/layout/navbar/RobotStatusBar";
import SidebarMap from "../components/layout/sidebar/SidebarMap";
import SidebarTeleop from "../components/layout/sidebar/SidebarTeleop";
import ControlBtn from "../components/joystick/ControlBtn";
import Stream from "../components/stream/Stream";
import "../App.css";

const names = ["James"];
const joymap = createJoymap();
const colorHash = new ColorHash({
  saturation: [0.1, 0.7, 0.8],
  lightness: 0.5,
});
const players = names.map((name) => {
  const module = createQueryModule();
  joymap.addModule(module);

  return {
    name,
    module,
    color: colorHash.hex(name),
  };
});

function Dashboard() {
  const [geofencezone, setgeofencezone] = useState();
  const [fleetzones, setfleetzones] = useState();
  const [nogozones, setnogozones] = useState();
  const [streamLive, setStreamLive] = useState(false);
  const [viewCount, setViewCount] = useState(0);
  const [streamView, setStreamView] = useState();
  const [streamStats, setStreamStats] = useState(0);
  const [showStreamStats, setShowStreamStats] = useState(false);
  const [, setIsPictureInPicture] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const [, updatedState] = useState({});
  const [conlock, setConLock] = useState(false);
  const [state, setState] = useState({
    testingmode: false,
    wandermode: false,
    ringlight: false,
    headlight: false,
    robotpathcheck: false,
    googlemap: false,
    streamclient: false,
  });

  const streamRef = useRef(null);

  const robotID = useLocation();
  const dispatch = useDispatch();

  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  const isLogged = localStorage.getItem("isLoggedin");
  const token = localStorage.getItem("token");
  const streamRefLocal = localStorage.getItem("streamRef");
  const lock = (conlock) => {
    setConLock(conlock);
  };
  const forceUpdate = useCallback(() => updatedState({}), []);

  let fleetId = localStorage.getItem("fleetId");
  let robotId = robotID?.state?.name?.robotId;

  const getbordertop = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "ERROR":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoSystem_fault_error_bordertop";
      case "AUTO_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "AUTO_RUN_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoRun_moving";
      case "AUTO_RUN":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "TELE_OP_OBSTACLE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center AutoObstacle_teleop_obstacle_bordertop";
      case "TELE_OP_MOVING":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center teleopMoving_bordertop";
      case "TELE_OP":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      case "PAYLOAD_STATE":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center payloadState_bordertop";
      case "MANUAL":
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center Teleop_autoRun_borderTop";
      default:
        return "Dashboard-Page-bottom-div-Main-wrapper d-flex-center defaultBorderTop";
    }
  };

  const getTeleopDetailsBorderTop = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "AutoSystem_fault_error_bordertop";
      case "ERROR":
        return "AutoSystem_fault_error_bordertop";
      case "AUTO_OBSTACLE":
        return "AutoObstacle_teleop_obstacle_bordertop";
      case "AUTO_RUN_OBSTACLE":
        return "Teleop_autoRun_borderTop";
      case "AUTO_RUN_MOVING":
        return "AutoRun_moving";
      case "AUTO_RUN":
        return "Teleop_autoRun_borderTop";
      case "TELE_OP_OBSTACLE":
        return "AutoObstacle_teleop_obstacle_bordertop";
      case "TELE_OP_MOVING":
        return "teleopMoving_bordertop";
      case "TELE_OP":
        return "Teleop_autoRun_borderTop";
      case "PAYLOAD_STATE":
        return "payloadState_bordertop";
      case "MANUAL":
        return "Teleop_autoRun_borderTop";
      default:
        return "defaultBorderTop";
    }
  };

  const openExtendedWindow = async () => {
    const windowDetails = await window.getScreenDetails();

    if (window.screen.isExtended) {
      windowDetails.screens.forEach((screen) => {
        if (!screen.isPrimary) {
          window.open(
            `/stream/${robotId}`,
            "",
            `width=${screen.availWidth},height=${screen.height},top=${screen.top},left=${screen.left},fullscreen=yes`
          );
        } else {
          localStorage.setItem("streamRef", "none");
        }
      });
    }
  };

  const handleExtendScreen = async () => {
    if (showStreamStats) {
      setShowStreamStats(false);
    }
    await openExtendedWindow();
  };

  const handleReduceScreen = () => {
    localStorage.setItem("streamRef", "block");
  };

  const togglePictureInPicture = () => {
    const videoElement = streamRef.current;

    if (!isMetadataLoaded) {
      console.log("Video metadata is not loaded yet.");
      return;
    }

    if (document.pictureInPictureElement === videoElement) {
      document
        .exitPictureInPicture()
        .then(() => {
          setIsPictureInPicture(false);
          streamRef.current.style.display = "block";
        })
        .catch((error) => {
          console.error("Failed to exit Picture-in-Picture mode:", error);
        });
    } else {
      videoElement
        .requestPictureInPicture()
        .then(() => {
          setIsPictureInPicture(true);
          // streamRef.current.style.display = "none";
        })
        .catch((error) => {
          console.error("Failed to enter Picture-in-Picture mode:", error);
        });
    }
  };

  const handleMetadataLoaded = () => {
    setIsMetadataLoaded(true);
  };

  const updateState = useCallback(async () => {
    let robotIdfromURL = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if (robotId === null || robotId === "" || robotId === undefined) {
      dispatch(storeRobotDetails(fleetId));
      let singlerobotdata = Object.keys(robotDetails).filter(
        (item) => item === robotIdfromURL
      );

      robotId = singlerobotdata.robotId;
    }

    if (isLogged === "LoginFalse") {
      window.location.replace("/");
    } else {
      dispatch(getRobotDetail(robotId));

      localStorage.setItem("kmlurl", robotDetails[robotId]?.kmlURL);
    }
  }, [dispatch, fleetId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();

      return () => {
        clearTimeout(timeout.current);
      };
    }, []);
  };

  useIntervalAsync(updateState, 2000);

  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined
    ) {
      window.location.replace("/");
    }

    getgeofenceZone(fleetId, token)
      .then((res) => {
        //  console.log('Geofence Response', res)
        if (!res.data.geofencezone) {
          setgeofencezone([]);
        } else {
          setgeofencezone(res.data.geofence);
        }
      })
      .catch((err) => {
        console.log("GeoFenceError", err);
      });

    getfleetzones(fleetId, token)
      .then((res) => {
        // console.log('FleetZone Response', res)
        if (!res.data.teleoperationZones) {
          setfleetzones([]);
        } else {
          setfleetzones(res.data.teleoperationZones);
        }
      })
      .catch((err) => {
        console.log("FleetZone errr", err);
      });

    getnogozones(fleetId, token)
      .then((res) => {
        // console.log('NogoZone Response', res)
        if (!res.data.nogoZones) {
          setnogozones([]);
        } else {
          setnogozones(res.data.nogoZones);
        }
      })
      .catch((err) => {
        console.log("NogoZoneERr", err);
      });
  }, []);

  useEffect(() => {
    MainStream(
      // robotDetails[robotId] && robotDetails[robotId].whepurl,
      setStreamLive,
      setViewCount,
      setStreamStats,
      robotId,
      setStreamView
    );
    joymap.setOnPoll(forceUpdate);
    joymap.start();

    return () => {
      joymap.stop();
    };
  }, [robotId]);

  useEffect(() => {
    if (!streamRefLocal) {
      localStorage.setItem("streamRef", "block");
    }
  });

  const {
    sidebar,
    setSideBar,
    showMinimizeBtn,
    setShowMinimizeButton,
    unlock,
    unlockController,
  } = useController();

  return (
    <>
      <div className="container-fluid DashBoard-page-Main-Whole-wrapper">
        <div
          className={getbordertop(robotDetails[robotId]?.robotRunningState)}
        ></div>
        <>
          <div className="main__controls">
            <RobotStatusBar
              robotId={robotId}
              robotDetails={robotDetails}
              unlockController={unlockController}
              state={state}
              setState={setState}
            />
            <main
              className={`${
                sidebar ? "main__container" : "main__container__toggle"
              } ${
                streamRefLocal === "none" &&
                sidebar &&
                "main__container__without_stream"
              }`}
            >
              <SidebarMap
                robotDetails={robotDetails}
                sidebar={sidebar}
                setSideBar={setSideBar}
                showMinimizeBtn={showMinimizeBtn}
                setShowMinimizeButton={setShowMinimizeButton}
                robotpathcheck={state.robotpathcheck}
                robotId={robotId}
                geofencezone={geofencezone}
                nogozones={nogozones}
                fleetzones={fleetzones}
                robotlatitude={
                  robotDetails[robotId] &&
                  parseFloat(robotDetails[robotId]?.latitude)
                }
                robotlongitude={
                  robotDetails[robotId] &&
                  parseFloat(robotDetails[robotId]?.longitude)
                }
                streamRefLocal={streamRefLocal}
              />
              <SidebarTeleop
                robotDetails={robotDetails}
                robotId={robotId}
                unlockController={unlockController}
                sidebar={sidebar}
                showMinimizeBtn={showMinimizeBtn}
                lock={lock}
                teleopBorder={
                  robotDetails[robotId] &&
                  getTeleopDetailsBorderTop(
                    robotDetails[robotId]?.robotRunningState
                  )
                }
                viewCount={viewCount}
                streamLive={streamLive}
              />
              <section className="video__stream">
                <Stream
                  streamRef={streamRef}
                  streamLive={streamLive}
                  handleMetadataLoaded={handleMetadataLoaded}
                  streamStats={streamStats}
                  setStreamStats={setStreamStats}
                  showStreamStats={showStreamStats}
                  streamView={streamView}
                  setShowStreamStats={setShowStreamStats}
                  isMetadataLoaded={isMetadataLoaded}
                  togglePictureInPicture={togglePictureInPicture}
                  handleExtendScreen={handleExtendScreen}
                  handleReduceScreen={handleReduceScreen}
                  streamRefLocal={streamRefLocal}
                />
              </section>
            </main>
            {streamLive &&
              map(
                ({ module, color }, index) => (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      bottom: "0rem",
                      right: "0rem",
                      display: unlock ? "" : "none",
                      pointerEvents: "none",
                    }}
                    key={"joystick__controller__" + index}
                  >
                    <ControlBtn
                      // key={"joystick__" + index}
                      module={module}
                      backgroundColor={color}
                      pressedColor={`rgb(41 255 2)`}
                      conlock={conlock}
                      robotId={robotId}
                    ></ControlBtn>
                  </div>
                ),
                players
              )}
          </div>
          <Modal
            open
            className="landscape__popup__container"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Change Orientation
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Please rotate your device.
              </Typography>
            </Box>
          </Modal>
        </>
      </div>
    </>
  );
}

export default memo(Dashboard);
