import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Header from "../components/layout/navbar/Header";
import IndividualRobot from "../components/robots/IndividualRobot";
import location from "../assets/svgs/location.svg";

function SelectFleet() {
  const [fleetList, setFleetList] = useState();
  const [showHeading, setShowHeading] = useState(false);
  const [headingFlag, setHeadingFlag] = useState(false);

  const navigate = useNavigate();
  const wholeData = useLocation();

  const robotMsgData = useSelector((state) => state.robotMessageReducer);
  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  const token = localStorage.getItem("token");
  const useremail = localStorage.getItem("useremail");

  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined ||
      useremail === null ||
      useremail === "null" ||
      useremail === undefined
    ) {
      window.location.replace("/");
    } else {
      setFleetList(wholeData?.state?.name?.fleet);
    }
    setHeadingFlag(false);
    Object.keys(robotDetails)?.map((data) => {
      if (
        robotDetails[data].teleop === "YES" &&
        robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
        robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
        robotDetails[data].robotRunningState !== "TELE_OP"
      ) {
        setHeadingFlag(true);
      }
      if (setHeadingFlag) {
        setShowHeading(true);
      }
      return null;
    });
  }, [wholeData, robotMsgData, showHeading, robotDetails, token, useremail]);

  const getRobotStatus = (item, data) => {
    switch (item.robotStatus) {
      case "AVAILABLE":
        return <p className="text-left m-0 p-color-green">Available</p>;
      case "UNAVAILABLE":
        return <p className="text-left m-0 p-color-red"> UnAvailable</p>;
      case "CHARGING":
        return <p className="text-left m-0 p-color-green">Charging</p>;
      case "ERROR":
        return <p className="text-left m-0 p-color-red"> Error</p>;
      case "Need Help":
        return <p className="text-left m-0 p-color-red"> Need Help</p>;
      default:
        return <p className="text-left m-0 p-color-red"> {item.robotStatus}</p>;
    }
  };
  const handleRobotData = (robotdata) => {
    localStorage.setItem("fleetId", robotdata?.fleetId);
    navigate(`/dashboard/${robotdata?.robotId}`, {
      state: { name: robotdata },
    });
  };

  const calculateFleetLength = (fleetId) => {
    let count = 0;
    Object.keys(robotDetails).map((id) => {
      if (robotDetails[id].fleetId === fleetId) {
        count++;
      }
      return null;
    });
    return count;
  };

  return (
    <>
      <Header useremail={useremail} />
      <div className="container-fluid SelectFleetPage-wrapper-Main">
        <div>
          {headingFlag && (
            <div>
              <h5 className="Select-Page-Robot-Message-Heading">
                {" "}
                ROBOT MESSAGE
              </h5>
            </div>
          )}
          <div className="SelectFleet-page-Error-box-wrapper">
            {headingFlag && (
              <audio autoPlay={true} loop>
                <source
                  src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                  type="audio/wav"
                ></source>
              </audio>
            )}

            {Object.keys(robotDetails).map(
              (data, index) =>
                robotDetails[data].teleop === "YES" &&
                robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
                robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
                robotDetails[data].robotRunningState !== "TELE_OP" && (
                  <div
                    key={"robot__detail_" + index}
                    className="SelectFleetPage-content-wrapper-Main border-red"
                    onClick={() => handleRobotData(robotDetails[data])}
                  >
                    <div className="border-blue-top SelectFleetPage-inner-li-div d-flex-center">
                      <button className="Error-state-teleop-ack-button">
                        {" "}
                        Teleop Ack
                      </button>
                      <div>
                        <div>
                          <h4 className="Select-page-fleetName">
                            {" "}
                            {robotDetails[data].fleetName}
                          </h4>
                        </div>
                        <div>
                          <p
                            className="SelectFleetPage-inner-li-div-robot-id"
                            style={{ color: "white" }}
                          >
                            {data}
                          </p>
                          {getRobotStatus(robotDetails[data], null)}
                        </div>
                      </div>

                      <div className="dashboard-left-side-div-one-inner-div">
                        <div className="dashboard-left-side-div-one-wrapper-battery-div">
                          <div className="battery-icon-css">
                            <span
                              style={{
                                width: "30px",
                                border: "1px solid #fff",
                                borderRadius: "4px",
                                padding: "2px",
                                height: "14px",
                                display: "inline-block",
                              }}
                            >
                              <strong
                                style={{
                                  background:
                                    parseInt(
                                      robotDetails[data].robotBattery
                                    ).toFixed(0) <= 20
                                      ? "#E4261C"
                                      : "rgb(113, 207, 98)",
                                  width: `${parseInt(
                                    robotDetails[data].robotBattery
                                  ).toFixed(0)}%`,
                                  display: "block",
                                  height: "8px",
                                }}
                              ></strong>
                            </span>
                          </div>
                          <p style={{ fontSize: "10px" }}>
                            {parseInt(robotDetails[data].robotBattery).toFixed(
                              0
                            )}
                            %
                          </p>
                        </div>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "5px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "10px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "15px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "20px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                            opacity: "0.5",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "25px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                            opacity: "0.5",
                          }}
                        ></span>
                        {/* <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/> */}
                        {data.robotStatus === "AVAILABLE" ? (
                          <PauseCircleIcon className="play-icon p-color-green" />
                        ) : (
                          <PlayCircleIcon className="play-icon p-color-red" />
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        <div className="home-main" style={{ width: "100%", marginTop: "50px" }}>
          {fleetList &&
            fleetList?.map((fleet, index) => {
              return (
                <div key={"card__" + index}>
                  <div className="cards">
                    <div className="card-outer-items">
                      <div className="card-outer-left">
                        <img src={location} alt="location" />
                        <h3 className="location-h3">{fleet.location}</h3>
                      </div>
                      <div className="card-outer-right">
                        <h2 className="card-total-robots">
                          {robotDetails && calculateFleetLength(fleet.fleetId)}
                        </h2>
                        <p style={{ margin: "0px" }}>robots</p>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-items">
                        <IndividualRobot
                          data={fleet}
                          latitude={fleet?.latitude}
                          longitude={fleet?.longitude}
                          fleetLength={calculateFleetLength(fleet.fleetId)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default memo(SelectFleet);
