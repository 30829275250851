import { useState } from "react";
import { IconContext } from "react-icons";
import { AiFillEye } from "react-icons/ai";
import controller from "../../../assets/svgs/controller.svg";
import AutorunMoving from "../../robots/AutorunMoving";
import TeleopCompleted from "../../robots/TeleopCompleted";
import RobotActions from "../../robots/RobotActions";

const SidebarTeleop = ({
  robotDetails,
  robotId,
  unlockController,
  sidebar,
  showMinimizeBtn,
  lock,
  teleopBorder,
  liveCount,
  viewCount,
  streamLive,
}) => {
  const [unlockChecked, setUnlockChecked] = useState(false);

  const handleUnlockSwitchClick = (streamLive) => {
    if (streamLive) {
      const newValue = !unlockChecked;
      setUnlockChecked(!unlockChecked);
      unlockController(newValue);
      lock(newValue);
    }
  };

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <aside
        style={sidebar ? { opacity: "1" } : { opacity: "0" }}
        className={`teleop__details ${teleopBorder}`}
      >
        <div className="robot__teleop__details__container">
          <div className="robot__teleop__details__header">
            <div className="robot__teleop">
              <AutorunMoving
                robotId={robotId}
                robotData={robotDetails[robotId]}
              />
            </div>
            <TeleopCompleted
              robotId={robotId}
              robotData={robotDetails[robotId]}
            />
          </div>
          <RobotActions robotId={robotId} robotData={robotDetails[robotId]} />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="robot__actions">
            <span className="teleop__watching">
              {" "}
              <AiFillEye className="watching__icon" />
              <strong>{viewCount}</strong> Watching
            </span>
          </div>
          <div className="users__info__panel">
            <div className="users__info">
              <div className="game__controller__status">
                <img src={controller} alt="controller svg" />
                <span className="controller__status">
                  {robotDetails[robotId] &&
                  robotDetails[robotId].controllerStatus
                    ? "ON"
                    : "OFF"}
                </span>
              </div>

              <div className="circle__icons"></div>
            </div>
            <div
              onClick={() => handleUnlockSwitchClick(streamLive)}
              className="unlock__btn"
              style={{ color: streamLive ? "#292F40" : "gray" }}
            >
              {unlockChecked ? "Lock Joystick" : "Unlock Joystick"}
            </div>
          </div>
        </div>
      </aside>
    </IconContext.Provider>
  );
};

export default SidebarTeleop;
