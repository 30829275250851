import { Link } from "react-router-dom";
import LogoutModal from "../modals/LogoutModal";
import user from "../../..//assets/svgs/user.svg";
import "../../../App.css";

function Header(props) {
  return (
    <div className="nav-main py-2">
      <div className="nav-items">
        <div className="nav-left">
          <div className="nav-logo">
            <Link onClick="">
              <img
                src="/assets/images/Ottonomy Logo_White_With TM-02.png"
                className="HeaderMain-logo-image"
                alt="main__logo"
              />
            </Link>
          </div>
        </div>

        <div className="nav-right">
          <div className="nav-right-1">
            <div className="nav-right-1-logo">
              <img src={user} alt="user" />
            </div>
            <div className="nav-right-1-info">
              <p className="user-email">{props.useremail}</p>
            </div>
          </div>
          <div className="white-line"></div>

          <LogoutModal header={true} />
        </div>
      </div>
    </div>
  );
}

export default Header;
