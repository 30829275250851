/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getFleetListAdmin, getFleetListUser, login } from "../apis/index";
import { storeRobotDetails, userlogin } from "../redux/actions";
import { useDispatch } from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../components/toast/toastType";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import "react-toastify/dist/ReactToastify.css";

let CryptoJS = require("crypto-js");
let Base64 = require("js-base64");

function Login() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPasswordHide, setLoginPasswordHide] = useState(true);

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let depcrptData = "";
  let autologinvar = searchParams.get("autologin");
  let token = localStorage.getItem("token");
  let email = localStorage.getItem("useremail");
  let role = localStorage.getItem("role");

  const loginSubmitHandler = (event) => {
    event.preventDefault();

    login({ email: loginEmail, password: loginPassword })
      .then((res) => {
        localStorage.setItem("useremail", loginEmail);
        localStorage.setItem("password", loginPassword);
        localStorage.setItem("Isloggedin", "Logintrue");
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("role", res.data.data.role[0]);

        showSuccessToast("Logged in successfully!");

        if (res.data.data.role[0] === "admin") {
          getFleetListAdmin(loginEmail, res.data.data.token)
            .then((res) => {
              navigate(`/selectfleet/${loginEmail}`, {
                state: { name: res?.data },
              });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          getFleetListUser(loginEmail, res.data.data.token)
            .then((res) => {
              res.data.fleet.length > 0
                ? navigate(`/selectfleet/${loginEmail}`, {
                    state: { name: res?.data },
                  })
                : navigate("/dashboard");
            })
            .catch((err) => {
              console.error(err);
            });
        }

        dispatch(userlogin({ email: loginEmail, password: loginPassword }));
      })
      .catch((err) => {
        showErrorToast("Please enter correct credentials!");
      });
  };

  const decryptData = (searchParams, secretKey) => {
    const base64Decrypted = Base64.decode(searchParams);
    const decrypted = CryptoJS.AES.decrypt(base64Decrypted, secretKey);
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  const autologin = () => {
    let values = JSON.parse(depcrptData);
    login(values)
      .then((res) => {
        localStorage.setItem("useremail", values.email);
        localStorage.setItem("password", values.password);
        localStorage.setItem("Isloggedin", "Logintrue");
        localStorage.setItem("token", res.data.data.token);
        console.log("Login Response respone", res);

        dispatch(storeRobotDetails(res.data.data.fleetId[0]));
        let robotData = res.data.data.filter(
          (item) => item[0].robotId === values.robotId
        );
        if (robotData[0][0].robotId) {
          showSuccessToast("Logged in successfully!");
          setTimeout(() => {
            navigate(`/dashboard/${robotData[0][0].robotId}`, {
              state: { name: robotData[0][0] },
            });
          }, 2000);
        } else {
          showErrorToast("Please enter correct credentials!");
        }

        dispatch(userlogin(values));
      })
      .catch((err) => {
        showErrorToast("Please enter correct credentials!");
      });
  };

  useEffect(() => {
    if (token) {
      if (role === "admin") {
        getFleetListAdmin(email, token)
          .then((res) => {
            navigate(`/selectfleet/${email}`, { state: { name: res?.data } });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        getFleetListUser(email, token)
          .then((res) => {
            res.data.fleet.length > 0
              ? navigate(`/selectfleet/${email}`, {
                  state: { name: res?.data },
                })
              : navigate("/dashboard");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (autologinvar) {
      try {
        depcrptData = decryptData(
          autologinvar,
          process.env.REACT_APP_SECRET_LOGIN_PASSPHRASE
        );
        autologin(depcrptData);
      } catch {
        navigate("/");
      }
    }
  }, []);

  return (
    <div className="DesktopMain-wrapper">
      <div className="Login_page-wholeMain-wrapper">
        <div className="LoginPage-Main-wrapper">
          <form onSubmit={loginSubmitHandler} className="Login-page-form">
            <img
              src="/assets/images/Ottonomy Logo_White_With TM-02.png"
              className="Login-page-form-logo-image"
              alt="logo"
            />

            <div className="Loginpage=-main-form">
              <div className="Loginpage-main-form-inner-div">
                <TextField
                  id="standard-size-normal"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon
                          style={{
                            color: "rgb(189,196,224)",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={loginEmail}
                  name="email"
                  onChange={(event) => setLoginEmail(event.target.value)}
                  placeholder="Email ID"
                />
              </div>

              <div className="Loginpage-main-form-inner-div">
                <TextField
                  sx={{ borderRadius: "0px", width: "100%" }}
                  fullWidth
                  id="password"
                  variant="standard"
                  type={`${loginPasswordHide ? "password" : "text"}`}
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(event) => setLoginPassword(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon
                          style={{
                            color: "rgb(189,196,224)",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setLoginPasswordHide(!loginPasswordHide)}
                        edge="end"
                        style={{ color: "rgb(189,196,224)" }}
                      >
                        {loginPasswordHide ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="Loginpage-btn-wrapper">
              <button type="submit" className="Loginpage-btn-wrapper-btn">
                {" "}
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
