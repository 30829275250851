import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "#282F42",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function LogoutModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const navigate = useNavigate();

  const handlelogoutbtn = () => {
    localStorage.setItem("useremail", null);
    localStorage.setItem("password", null);
    localStorage.setItem("token", null);
    localStorage.setItem("fleetID", null);
    localStorage.setItem("Isloggedin", "LoginFalse");
    localStorage.setItem("mapid", null);
    localStorage.setItem("Distance", null);
    localStorage.setItem("robotId", null);
    localStorage.setItem("kmlurl", null);
    navigate("/");
  };
  return (
    <>
      {props.header ? (
        <Button
          className="btn-dashboard-red-color cancel-order"
          onClick={handleOpen}
          style={{
            color: "white",
            padding: "0px",
            textTransform: "capitalize",
            fontFamily: "Poppins",
            fontSize: "15px",
          }}
        >
          {props.dashboard ? (
            "Logout"
          ) : (
            <PowerSettingsNewIcon
              style={{ color: "#b1b1b1" }}
              className="Heade_Power_button"
            />
          )}
        </Button>
      ) : (
        <Button
          className="cancel-order logout-button-footer"
          onClick={handleOpen}
          style={{
            color: "white",
            padding: "0px",
            textTransform: "capitalize",
            fontFamily: "Poppins",
            fontSize: "0.75rem",
            minWidth: "auto",
          }}
        >
          {props.dashboard ? (
            "Logout"
          ) : (
            <PowerSettingsNewIcon
              style={{ color: "#b1b1b1" }}
              className="Heade_Power_button"
            />
          )}
        </Button>
      )}

      <Modal
        open={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Cancel-order-Modal-Mainwrapper">
            <p> Are you sure want to logout ?</p>
            <div className="Modal-button-Main-wrapper">
              <Button
                className="Modal-button-Main-btn yes-red-active"
                onClick={() => {
                  handleClose();
                  handlelogoutbtn();
                }}
              >
                {" "}
                Yes
              </Button>
              <Button className="Modal-button-Main-btn" onClick={handleClose}>
                {" "}
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(LogoutModal);
